<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>数据分析</el-breadcrumb-item>
        <el-breadcrumb-item>人员分析</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-header">
      <div></div>
      <div class="yt-header-toolbar">
        <el-input v-model="jobNumber" placeholder="输入工号搜索" class="yt-search" @keydown.enter.native="reSearchStaff">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="reSearchStaff" />
        </el-input>
        <el-input v-model="keyword" placeholder="通过人员名称模糊搜索" class="yt-search" @keydown.enter.native="reSearchStaff">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="reSearchStaff" />
        </el-input>
      </div>
    </div>
    <div class="yt-container yt-container-flex" :class="{ 'is-pack-up': isPackUp }" style="height: calc(100% - 48px)">
      <div class="yt-content-tree">
        <template v-if="isPackUp">
          <YTIcon class="pack-up-button" :href="'#icon-zhankai'" @click="isPackUp = false" />
        </template>
        <template v-else>
          <div class="header">
            <YTIcon class="pack-up-button" :href="'#icon-shouqi1'" @click="isPackUp = true" />
            <div>
              <YTIcon style="margin: 0 5px 0 0; font-size: 20px" :href="'#icon-fenlei1'" />
              <p>{{ organizationData.hasOwnProperty('name') ? organizationData.name : '' }}</p>
            </div>
          </div>
        </template>
        <div class="tree">
          <el-tree ref="department-tree" :data="departmentData" node-key="departmentId" highlight-current @node-click="selectTree">
            <div slot-scope="{ node, data }" class="tree-node">
              <el-tooltip effect="dark" :content="data.name" :disabled="data.name.length < 10" placement="top-start">
                <div class="el-tree-node__label">{{ data.name }}</div>
              </el-tooltip>
            </div>
          </el-tree>
        </div>
      </div>
      <div class="yt-resize">
        ⋮
      </div>
      <div class="yt-content">
        <el-table
          class="yt-table"
          @row-click="toDetail"
          :data="tableData"
          height="calc(100% - 35px)"
          v-loading="loading"
          element-loading-text="加载中"
        >
          <el-table-column prop="realName" label="姓名" show-overflow-tooltip />
          <el-table-column prop="jobNumber" label="工号" show-overflow-tooltip />
          <el-table-column prop="postName" label="岗位" show-overflow-tooltip />
        </el-table>
        <Page
          class="yt-page"
          :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
          :total="total"
          :current="pageNum + 1"
          :page-size-opts="[10, 20, 40, 100]"
          :page-size="pageSize"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
          show-elevator
          show-sizer
          show-total
        />
      </div>
    </div>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import departmentApi from '@/api/department'
import staffApi from '@api/staff'

export default {
  name: 'Rank',
  components: { YTIcon },
  data() {
    return {
      keyword: '',
      jobNumber: '',
      departmentId: 0,
      isPackUp: false,
      departmentData: [
        {
          name: '所有人员',
          departmentId: 0
        }
      ],
      organizationData: {}, //企业信息
      loading: true,
      tableData: [],
      pageNum: 0,
      pageSize: 10,
      total: 0
    }
  },
  created() {
    this.getDepartment()
  },
  mounted() {
    this.$dragDiv()
  },
  beforeRouteEnter(to, from, next) {
    to.meta.keepAlive = from.path.includes('/analysis/assessment')
    next()
  },
  methods: {
    getDepartment() {
      departmentApi
        .getDepartmentsTree()
        .then(res => {
          this.departmentData = this.departmentData.concat(res.res.children)
          this.organizationData = res.res
          this.organizationId = res.res.departmentId
          this.setCurrentKey()
        })
        .finally(() => {
          this.searchStaff()
        })
    },
    setCurrentKey(departmentId) {
      this.$nextTick(() => {
        this.$refs['department-tree'].setCurrentKey(departmentId ? departmentId : 0)
      })
    },
    selectTree(data) {
      this.departmentId = data.departmentId
      this.pageNum = 0
      this.pageSize = 10
      this.searchStaff()
    },
    searchStaff() {
      this.loading = true
      let searchStaffForm = {
        jobNumber: this.jobNumber,
        keyword: this.keyword,
        departmentId: this.departmentId === 0 ? null : this.departmentId,
        status: 0 // 0是在职人员
      }
      staffApi
        .searchStaffs(searchStaffForm, this.pageNum, this.pageSize)
        .then(res => {
          if (res.code === 0) {
            this.total = res.res.total
            res.res.data.forEach(item => {
              item.gender = item.gender ? '男' : '女'
            })
            this.tableData = res.res.data
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    reSearchStaff() {
      this.pageNum = 0
      this.searchStaff()
    },
    toDetail(row) {
      this.$router.push({ name: 'staffDetail', params: { userId: row.userId } })
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      this.searchStaff()
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize
      this.searchStaff()
    }
  }
}
</script>

<style scoped lang="less">
@import '~@/theme/dragDiv';
</style>
